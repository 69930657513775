import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Title from '../components/title'
import Grid from '../components/grid'
import LiveShow from '../components/live_show'
import PageSEO from '../components/page_seo'

const PageLiveShows = () => {

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: {fields: tours___start_date}) {
        edges {
          node {
            first_name
            surname
            tours {
              end_date(formatString: "X")
              location
              tour_type
              start_date(formatString: "X")
              tickets_url
              title
              poster {
                ...ImageWithPreview
              }
            }
            headshot {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  `)

  const artists_with_shows = data.allSanityArtist.edges.filter(s => s.node.tours.length > 0);

  const edinburgh_show_types = ['both', 'edinburgh']
  const edinburgh_shows = []

  artists_with_shows.map(a => {
    const this_artists_shows = a.node.tours.filter(t => edinburgh_show_types.includes(t.tour_type));

    this_artists_shows.map(t => {
      edinburgh_shows.push({
        title: t.title,
        location: t.location,
        start_date: t.start_date,
        tour_type: t.tour_type,
        end_date: t.end_date,
        url: t.tickets_url,
        name: `${a.node.first_name} ${a.node.surname}`,
        image: a.node.headshot,
        poster: t.poster
      })
    })

  });

  edinburgh_shows.filter(Boolean);

  const now = new Date();
  now.setHours(0, 1, 0, 0);
  const currentTimestamp = Math.floor(now.getTime() / 1000);
  const filteredShows = edinburgh_shows.filter(show => show.end_date >= currentTimestamp);
  const sortedFilteredShows = filteredShows.sort((a, b) => a.start_date - b.start_date);

  return (
    <>
      <PageSEO path="/ontour" />
      <Title>Edinburgh</Title>
      <Grid maxwidth="1240px" colgap="130px" rowgap="53px" mt="52px">
        {sortedFilteredShows.map((c, index) => {
          return (
            <LiveShow key={index} show={c} />
          )
        })}
      </Grid>
    </>
  )
}

export default PageLiveShows;
